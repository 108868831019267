<template>
  <div>
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12">
        <div>
          <b-button
            variant="danger"
            @click="$router.push({ name: 'tablekuruplun' })"
          >
            กลับ
          </b-button>
           <b-button
            variant="danger ml-1"
            @click="$router.push({ name: 'tablekuruplun' })"
          >
            PDF
          </b-button>
          
          <div class="title head">
            <p class="text-center h1">
              รายการทรัพย์สิน
            </p>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">เลขทะเบียน :</div>
            <div class="ml-1 h4">2564-0001</div>
            <div class="ml-1 h4 text-primary" text="primary">วันที่ :</div>
            <div class="ml-1 h4">13/01/2565</div>
          </div>
           <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">ผู้เบิก :</div>
            <div class="ml-1 h4">เจ้าหน้าที่บริหารงานทั่วไป</div>
            <div class="ml-1 h4 text-primary" text="primary">กลุ่มงาน :</div>
            <div class="ml-1 h4">กลุ่มงานบริหารการทดสอบและศูนย์เครือข่าย</div>
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">รายละเอียด :</div>
            <div class="ml-1 h4"></div>            
          </div>
          <div class="row justify-content-start">
            <div class="ml-1 h4 text-primary" text="primary">สถานะทรัพย์สิน :</div>
            <div class="ml-1 h4">ยืม</div>
             <div class="ml-1 h4 text-primary" text="primary">วันที่:</div>
            <div class="ml-1 h4">13/01/2565 - 01/02/2565</div>                   
          </div>
          <hr />
          <div class="row justify-content-start"></div>
          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap"
              >
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.fullName }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3', '5', '10']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">of {{ props.total }} entries</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import {
  BTable,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { BButton } from 'bootstrap-vue'
import { BFormFile } from 'bootstrap-vue'
import { BFormSelect } from 'bootstrap-vue'
import { BFormDatepicker } from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import { data } from 'vue-echarts'

export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  }, 
  data() {
    return {
      pageLength: 5,
      dir: false,
      selected: null,
     
      columns: [
        {
          label: 'วัน เดือน ปี',
          field: 'วันที่',
        },
        {
          label: 'หมายเลขทรัพย์สิน',
          field: 'หมายเลขทรัพย์สิน',
        },
        {
          label: 'Serial Number',
          field: 'Serial',
        },
        {
          label: 'รายการ  ยี่ห้อ  ชนิด  แบบและลักษณะ',
          field: 'รายการ',
        },
        {
          label: 'จำนวน',
          field: 'จำนวน',
        },
        {
          label: 'หน่วยนับ',
          field: 'หน่วยนับ',
        },
        {
          label: 'ราคาต่อหน่วย',
          field: 'ราคาต่อหน่วย',
        },
      ],
      rows: [
        {
          วันที่: '13-ม.ค.-64',
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ:
            'เครื่องบันทึกเวลาเข้า-ออก การปฏิบัติงานของเจ้าหน้าที่ สทศ. (รวมค่าติดตั้ง)',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ:
            'Hikvision DS-K1T671MF Face Recognition Terminal 7 inch. 6000 Faces, 5,000 Finger Mifare',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ: '',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ: '',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ: '',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ: '',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ: '',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
        {
          หมายเลขทรัพย์สิน: '01-014-0001/64',
          Serial: 'S/N : F69155113',
          รายการ: '',
          จำนวน: '1',
          หน่วยนับ: 'เครื่อง',
          ราคาต่อหน่วย: '18,900.00',
        },
      ],
      searchTerm: '',
    }
  },
  methods: {
    getfrom() {
      // console.log(id)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.card {
  padding: 1rem;
}
.mid {
  padding: 5px;
}
</style>
